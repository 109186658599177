<template>
	<S2SCard title="Staff">
		<SimpleDialog v-model="editUser" title="Edit User" class="simple-dialog">
			<v-flex>
				<v-row v-if="userData">
					<v-col cols="12" sm="6">
						<v-text-field v-model="userData.firstName" label="Name"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field v-model="userData.lastName" label="Surname"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field v-model="userData.email" label="Email"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field v-model="userData.mobile" label="Cellphone Number"></v-text-field>
					</v-col>
				</v-row>
			</v-flex>
			<v-layout class="justify-end" slot="button-container">
				<v-btn text @click="editUser = false" class="mr-2">Cancel</v-btn>
				<v-btn color="accent" @click="updateUserData()">Update</v-btn>
			</v-layout>
		</SimpleDialog>

		<v-layout row wrap slot="toolbar-content" justify-end mr-4>
			<v-flex xs6 md6>
				<v-autocomplete
					v-model="selectedUser"
					:loading="searching"
					:items="searchedUsers"
					:search-input.sync="search"
					cache-items
					class="mx-2 mb-2"
					hide-no-data
					hide-details
					label="Search for user"
					item-text="email"
					return-object
				></v-autocomplete>
			</v-flex>
			<v-flex xs3 md1 v-if="selectedUser.id">
				<v-btn color="accent" @click="onUserAdd()">Add</v-btn>
			</v-flex>
		</v-layout>

		<v-data-table :headers="userTableHeaders" :items="users" class="elevation-1" :loading="status.loading" item-key="id">
			<template v-slot:item.name="{ item }">
				<div>{{ item.profile.firstName }} {{ item.profile.surname }}</div>
			</template>
			<template v-slot:item.username="{ item }">{{ item.profile.email }}</template>
			<template v-slot:item.manager="{ item }">
				<v-checkbox
					class="justify-center ma-auto"
					:input-value="userHasGroup(3, item.groups)"
					color="primary"
					@change="onGroupChange($event, 3, item)"
					hide-details
				></v-checkbox>
			</template>
			<template v-slot:item.viewer="{ item }">
				<v-checkbox
					class="justify-center ma-auto"
					:input-value="userHasGroup(200, item.groups)"
					color="primary"
					@change="onGroupChange($event, 200, item)"
					hide-details
				></v-checkbox>
			</template>
			<template v-slot:item.admin="{ item }">
				<v-checkbox
					class="justify-center ma-auto"
					:input-value="userHasGroup(2, item.groups)"
					color="primary"
					@change="onGroupChange($event, 2, item)"
					hide-details
				></v-checkbox>
			</template>
			<template v-slot:item.primaryContact="{ item }">
				<v-btn :disabled="userIsPrimary(item.id)" color="primary" @click="assignPrimary(item)">
					{{ userIsPrimary(item.id) ? "Primary" : "Assign" }}
				</v-btn>
			</template>
			<template v-slot:item.action="{ item }">
				<v-flex>
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn icon color="accent" @click="getUserData(item)" v-on="on">
								<v-icon>edit</v-icon>
							</v-btn>
						</template>
						<span>Edit User {{ item.profile.firstName }}</span>
					</v-tooltip>

					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn icon color="primary" @click="resetUserPassword(item.id)" v-on="on">
								<v-icon>mdi-lock-reset</v-icon>
							</v-btn>
						</template>
						<span>Reset Password for {{ item.profile.firstName }}</span>
					</v-tooltip>

					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn icon color="primary" @click="viewEmailActivity(item.profile.email)" v-on="on">
								<v-icon>mdi-email-search-outline</v-icon>
							</v-btn>
						</template>
						<span>View {{ item.profile.firstName }}'s Email Activity</span>
					</v-tooltip>

					<v-btn :disabled="userIsPrimary(item.id)" @click="onDeleteUser(item, item.groups)" icon>
						<v-icon color="primary">delete</v-icon>
					</v-btn>
				</v-flex>
			</template>
		</v-data-table>
	</S2SCard>
</template>

<script>
import Vue from "vue";
import SimpleDialog from "@/components/SimpleDialog.vue";

export default Vue.extend({
	name: "Staff",

	components: { SimpleDialog },

	computed: {
		users: function() {
			return this.$store.state.organisations.users.users;
		},
		status: function() {
			return this.$store.state.organisations.users.status;
		},
		searchedUsers: function() {
			return this.$store.state.organisations.users.searchedUsers;
		},
		searching: function() {
			return this.$store.state.organisations.users.searching;
		},
		selectedOrganisation: function() {
			return this.$store.state.organisations.selectedOrganisation;
		},
		kcUser: function() {
			return this.$store.state.user.kcUser;
		}
	},

	watch: {
		search: function(searchVal) {
			if (!searchVal) return;
			this.$store.dispatch("organisations/users/searchUsersByEmail", searchVal);
		},
		kcUser: function(val) {
			this.userData = {
				firstName: val.firstName,
				lastName: val.lastName,
				email: val.email,
				mobile: val.attributes.mobile ? val.attributes.mobile : []
			};
		}
	},

	data: function() {
		return {
			selectedUser: {},
			search: "",

			//keycloak
			editUser: false,
			userData: {},

			userTableHeaders: [
				{
					text: "Name",
					value: "name"
				},
				{
					text: "Username",
					value: "username"
				},
				{
					text: "Manager",
					align: "center",
					value: "manager"
				},
				{
					text: "Viewer",
					align: "center",
					value: "viewer"
				},
				{
					text: "Administrator",
					align: "center",
					value: "admin"
				},
				{
					text: "Primary Contact",
					value: "primaryContact"
				},
				{
					text: "Action",
					align: "center",
					value: "action"
				}
			]
		};
	},

	mounted: function() {
		this.$store.dispatch("organisations/users/fetchUsers");
	},

	methods: {
		onUserAdd() {
			this.$store.dispatch("organisations/addUserToOrganisation", {
				organisationId: this.selectedOrganisation.id,
				user: this.selectedUser,
				groups: [200]
			});
		},
		assignPrimary(user) {
			this.$store.dispatch("organisations/addPrimaryUserToOrganisation", {
				organisationId: this.selectedOrganisation.id,
				user
			});
		},
		userHasGroup(groupId, userGroups) {
			for (let group of userGroups) {
				if (group.id === groupId) return true;
			}
		},
		userIsPrimary(userId) {
			if (this.selectedOrganisation.profile.contactPerson) return this.selectedOrganisation.profile.contactPerson.id === userId;
			else return this.selectedOrganisation.profile.id == userId;
		},
		onGroupChange(val, groupId, user) {
			if (!val) this.$store.dispatch("organisations/removeGroupFromUser", { organisationId: this.selectedOrganisation.id, groups: [groupId], user });
			else
				this.$store.dispatch("organisations/addUserToOrganisation", {
					organisationId: this.selectedOrganisation.id,
					groups: [groupId],
					user,
					successMessage: "Roles Updated"
				});
		},
		onDeleteUser(user, userGroups = []) {
			let groups = [];
			for (let group of userGroups) groups.push(group.id);
			this.$store.dispatch("organisations/removeGroupFromUser", { organisationId: this.selectedOrganisation.id, groups, user });
		},
		async getUserData(user) {
			await this.$store.dispatch("user/getUser", user.id);
			this.editUser = true;
		},
		async updateUserData() {
			const cellphone = this.userData.mobile ? this.userData.mobile : [];
			let data = {
				firstName: this.userData.firstName,
				lastName: this.userData.lastName,
				email: this.userData.email,
				attributes: {
					mobile: cellphone
				}
			};

			await this.$store.dispatch("user/updateUser", { uid: this.kcUser.id, userData: data });
			this.editUser = false;
		},
		viewEmailActivity(email) {
			this.$router.push({ name: "email-activity", params: { defaultEmail: email } });
		},
		resetUserPassword(id) {
			this.$store.dispatch("user/resetUserPassword", id);
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
	font-weight: normal;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}
</style>
